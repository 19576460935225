import React from 'react'

function ArrowIonicLeftIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="#393939"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.306 5.49756L6.27959 1.52696C6.57347 1.23307 6.57347 0.757853 6.27959 0.467093C5.98571 0.176333 5.51051 0.176333 5.21663 0.467093L0.714683 4.96606C0.430183 5.25057 0.423933 5.70703 0.692793 6.00092L5.2135 10.5312C5.36044 10.6781 5.55427 10.75 5.74498 10.75C5.93569 10.75 6.12952 10.6781 6.27646 10.5312C6.57034 10.2373 6.57034 9.76204 6.27646 9.47128L2.306 5.49756Z"
      />
    </svg>
  )
}

export default ArrowIonicLeftIcon
