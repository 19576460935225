import React from 'react'

function ArrowIonicRightIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="#393939"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69414 5.49755L0.720417 1.52696C0.426528 1.23307 0.426528 0.757853 0.720417 0.467093C1.01431 0.176333 1.48953 0.176333 1.78341 0.467093L6.2855 4.96605C6.57001 5.25056 6.57626 5.70702 6.30739 6.00091L1.78654 10.5311C1.6396 10.6781 1.44576 10.75 1.25504 10.75C1.06433 10.75 0.870488 10.6781 0.723538 10.5311C0.429648 10.2373 0.429648 9.76203 0.723538 9.47127L4.69414 5.49755Z"
      />
    </svg>
  )
}

export default ArrowIonicRightIcon
