import React from 'react'

function CalendarIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="gray-300"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9039 2.72621H18.3769V1.35165C18.3769 0.59564 17.7813 0 17.0253 0C16.2693 0 15.6736 0.59564 15.6736 1.35165V2.72621H12.3518V1.35165C12.3518 0.59564 11.7561 0 11.0001 0C10.2441 0 9.64848 0.59564 9.64848 1.35165V2.72621H6.32663V1.35165C6.32663 0.59564 5.73099 0 4.97498 0C4.21897 0 3.62333 0.59564 3.62333 1.35165V2.72621H3.09642C1.46986 2.72621 0.141113 4.05495 0.141113 5.68151V18.8086C0.141113 20.4351 1.46985 21.7639 3.09642 21.7639H18.9039C20.5304 21.7639 21.8592 20.4351 21.8592 18.8086V5.68151C21.8592 4.05495 20.5304 2.72621 18.9039 2.72621H18.9039ZM18.9039 19.7937H3.0964C2.54658 19.7937 2.1113 19.3584 2.1113 18.8086V8.79719H19.889V18.8086C19.889 19.3584 19.4537 19.7937 18.9039 19.7937Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26582 10.767H4.53961C4.33343 10.767 4.15015 10.9502 4.15015 11.1564V12.8975C4.15015 13.1037 4.33343 13.287 4.53961 13.287H7.26582C7.472 13.287 7.65528 13.1037 7.65528 12.8975V11.1564C7.65528 10.9502 7.472 10.767 7.26582 10.767Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3747 10.767H9.62554C9.41936 10.767 9.23608 10.9502 9.23608 11.1564V12.8975C9.23608 13.1037 9.41936 13.287 9.62554 13.287H12.3747C12.5809 13.287 12.7641 13.1037 12.7641 12.8975V11.1564C12.7641 10.9502 12.5809 10.767 12.3747 10.767Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4838 10.767H14.7347C14.5285 10.767 14.3452 10.9502 14.3452 11.1564V12.8975C14.3452 13.1037 14.5285 13.287 14.7347 13.287H17.4609C17.6671 13.287 17.8504 13.1037 17.8504 12.8975V11.1564C17.8733 10.9502 17.69 10.767 17.4838 10.767H17.4838Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26582 15.281H4.53961C4.33343 15.281 4.15015 15.4643 4.15015 15.6705V17.4116C4.15015 17.6178 4.33343 17.801 4.53961 17.801H7.26582C7.472 17.801 7.65528 17.6178 7.65528 17.4116V15.6705C7.65528 15.4643 7.472 15.281 7.26582 15.281Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3747 15.281H9.62554C9.41936 15.281 9.23608 15.4643 9.23608 15.6705V17.4116C9.23608 17.6178 9.41936 17.801 9.62554 17.801H12.3747C12.5809 17.801 12.7641 17.6178 12.7641 17.4116V15.6705C12.7641 15.4643 12.5809 15.281 12.3747 15.281Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4838 15.281H14.7347C14.5285 15.281 14.3452 15.4643 14.3452 15.6705V17.4116C14.3452 17.6178 14.5285 17.801 14.7347 17.801H17.4609C17.6671 17.801 17.8504 17.6178 17.8504 17.4116V15.6705C17.8733 15.4643 17.69 15.281 17.4838 15.281H17.4838Z"
      />
    </svg>
  )
}

export default CalendarIcon
